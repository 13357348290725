<template>
  <div class="register-container">
    <div class="content">
      <img src="/images/bg.png" class="img-bg" />
      <div class="register">
        <div class="register-content">
          <div class="register_title title">
            <!-- <img src="/images/logo.png" alt /> -->
          </div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-position="right"
            label-width="182px"
            class="register-page"
          >
            <el-form-item label="用户名：" prop="realname">
              <el-input
                v-model.trim="ruleForm.realname"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>

            <!-- 性别 -->
            <!-- <el-form-item label="性别：" prop="gender">
              <el-radio-group v-model="ruleForm.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <!-- 用户名： -->
            <el-form-item label="单位名称：" prop="username">
              <el-input
                v-model.trim="ruleForm.username"
                placeholder="请输入单位名称"
              ></el-input>
            </el-form-item>
             <!-- 联系方式： -->
            <el-form-item label="法人手机或管理员手机：" prop="phone">
              <el-input
                v-model.trim="ruleForm.phone"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <!-- 密码： -->
            <el-form-item label="密码：" prop="password">
              <el-input
                v-model.trim="ruleForm.password"
                type="password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
           
            <!-- 验证码： -->
            <el-form-item
              label="验证码："
              prop="phoneCode"
              style="position: relative"
            >
              <el-col :span="14">
                <el-input
                  v-model.trim="ruleForm.phoneCode"
                  type="text"
                  placeholder="验证码"
                >
                </el-input
              ></el-col>
              <el-col :span="10">
                <span class="code-box" @click="getImgCode"
                  ><img :src="imgCode" alt=""
                /></span>
                <!-- <el-button
                type="primary"
                size="mini"
                :disabled="isGetphone"
                @click="getPhoneCode"
              >
                {{ phoneCode }}
              </el-button> -->
              </el-col>
            </el-form-item>
            <!-- 邮箱： -->
            <el-form-item label="实名认证：" prop="email">
              <el-input ></el-input>
            </el-form-item>

            <!--  用户描述：-->
            <!-- <el-form-item label="用户描述：">
              <el-input
                v-model.trim="ruleForm.description"
                type="textarea"
              ></el-input>
            </el-form-item> -->
              <!--  是否同意用户协议-->
            <el-form-item label="">
              <el-checkbox >是否同意<a class="link" @click="dialogVisible =true">隐私保护或用户协议</a></el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                style="width: 100%"
                size="large"
                sort="loginBtn"
                @click="handleReister"
                :loading="logining"
                >注册</el-button
              >
            </el-form-item>
            <el-form-item>
              <span class="span-blue">已有账号？去<span class="span-login" @click="goLogin">登录</span></span>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog
  title="溯源平台用户注册服务条款和声明"
  :visible.sync="dialogVisible"
  width="60%">
  <div class="detail-content">
                                <p class="welcome">
                                    欢迎您加入溯源平台用户！请仔细阅读下面的协议，只有接受协议才能继续进行注册。</p>
                                <div class="texts">
                                    <ol>
                                        <li><b>服务条款的确认和接纳</b>
                                            <p>
                                                溯源平台同意按照本协议的规定及其不时发布的操作规则提供基于互联网的相关服务（以下简称"网络服务"），为获得网络服务，服务使用人（以下简称"用户"）应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击"同意"按钮即表示用户完全接受本协议项下的全部条款。</p>
                                        </li>
                                        <li><b>溯源平台网服务简介</b>
                                            <p>
                                                溯源平台网络服务的具体内容由溯源平台根据实际情况提供，例如论坛(BBS)、业内动态和搜索等等。溯源平台保留随时变更、中断或终止部分或全部网络服务的权利。</p>
                                            <p>
                                                溯源平台在提供网络服务时，可能会对部分网络服务（例如高级查询）的用户收取一定的费用。在此情况下，溯源平台会在相关页面上做明确的提示。如用户拒绝支付该等费用，则不能使用相关的网络服务。</p>
                                        </li>
                                        <li>使用规则
                                            <h5>
                                                用户同意：</h5>
                                            <p>
                                                1)购置设备，包括个人电脑一台、调制解调器一个及配备上网装置。</p>
                                            <p>
                                                2)个人上网和支付与此服务有关的电话费用、网络费用。</p>
                                            <h5>
                                                用户必须：</h5>
                                            <p>
                                                1)提供及时、详尽及准确的个人资料。</p>
                                            <p>
                                                2)不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。</p>
                                            <p>
                                                3)用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》等有关计算机及互联网规定的法律和法规、实施办法。在任何情况下，溯源平台合理地认为用户的行为可能违反上述法律、法规，溯源平台可以在任何时候，不经事先通知终止向该用户提供服务。用户应了解国际互联网的无国界性，应特别注意遵守当地所有有关的法律和法规。</p>
                                            <p>
                                                用户在申请使用溯源平台网络服务时，必须向溯源平台提供准确的个人资料，如个人资料有任何变动，必须及时更新。</p>
                                            <p>
                                                用户注册成功后，溯源平台将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
                                            <p>
                                                用户在使用溯源平台网络服务过程中，必须遵循以下原则：</p>
                                            <p>
                                                1) 遵守中国有关的法律和法规；</p>
                                            <p>
                                                2) 不得为任何非法目的而使用网络服务系统；</p>
                                            <p>
                                                3) 遵守所有与网络服务有关的网络协议、规定和程序；</p>
                                            <p>
                                                4) 不得利用溯源平台网络服务系统进行任何可能对互联网的正常运转造成不利影响的行为；</p>
                                            <p>
                                                5) 不得利用溯源平台网络服务系统传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</p>
                                            <p>
                                                6) 不得利用溯源平台网络服务系统进行任何不利于溯源平台的行为；</p>
                                            <p>
                                                7) 如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告溯源平台。</p>
                                        </li>
                                        <li><b>服务修订</b>
                                            <p>
                                                溯源平台保留随时修改或中断服务而不需知照用户的权利。溯源平台行使修改或中断服务的权利，不需对用户或第三方负责。</p>
                                        </li>
                                        <li><b>网站内容的所有权</b>
                                            <p>
                                                溯源平台提供的网络服务内容可能包括：文字、软件、声音、图片、录象、图表等。所有这些内容受版权、商标和其它财产所有权法律的保护。</p>
                                            <p>
                                                用户只有在获得溯源平台或其他相关权利人的授权之后才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</p>
                                        </li>
                                        <li><b>用户隐私制度</b>
                                            <p>
                                                保护用户隐私是溯源平台的一项基本政策，溯源平台保证不对外公开或向第三方提供用户注册资料及用户在使用网络服务时存储在溯源平台的非公开内容，但下列情况除外：</p>
                                            <p>
                                                1) 事先获得用户的明确授权；</p>
                                            <p>
                                                2) 根据有关的法律法规要求；</p>
                                            <p>
                                                3) 按照相关政府主管部门的要求；</p>
                                            <p>
                                                4) 为维护社会公众的利益；</p>
                                            <p>
                                                5) 为维护溯源平台的合法权益。</p>
                                            <p>
                                                溯源平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与溯源平台同等的保护用户隐私的责任，则溯源平台可将用户的注册资料等提供给该第三方。</p>
                                            <p>
                                                在不透露单个用户隐私资料的前提下，溯源平台有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
                                        </li>
                                        <li>服务变更、中断或终止
                                            <p>
                                                如因系统维护或升级的需要而需暂停网络服务，溯源平台将尽可能事先进行通告。</p>
                                            <p>
                                                如发生下列任何一种情形，溯源平台有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户：</p>
                                            <p>
                                                1) 用户提供的个人资料不真实；</p>
                                            <p>
                                                2) 用户违反本协议中规定的使用规则。</p>
                                            <p>
                                                除前款所述情形外，溯源平台同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，溯源平台无需对用户或任何第三方承担任何责任。</p>
                                        </li>
                                        <li>有限责任
                                            <p>
                                                用户同意保障和维护溯源平台及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给溯源平台或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</p>
                                        </li>
                                        <li>修改协议
                                            <p>
                                                溯源平台将可能不时的修改本协议的有关条款，一旦条款内容发生变动，溯源平台将会在相关的页面提示修改内容。</p>
                                            <p>
                                                如果不同意溯源平台对服务条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受服务条款的变动。</p>
                                        </li>
                                        <li>用户责任
                                            <p>
                                                用户单独承担传输内容的责任。用户必须遵循：</p>
                                            <p>
                                                1)从中国境内向外传输技术性资料时必须符合中国有关法规。
                                            </p>
                                            <p>
                                                2)使用邮件服务不作非法用途。</p>
                                            <p>
                                                3)不干扰或混乱网络服务。</p>
                                            <p>
                                                4)不在论坛BBS或留言簿发表任何与政治相关的信息。</p>
                                            <p>
                                                5)遵守所有使用邮件服务的网络协议、规定、程序和惯例。</p>
                                            <p>
                                                6)不得利用本站危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益。</p>
                                            <p>
                                                7)不得利用本站制作、复制和传播下列信息：</p>
                                            <div class="inner-text">
                                                <p>
                                                    1、煽动抗拒、破坏宪法和法律、行政法规实施的；</p>
                                                <p>
                                                    2、煽动颠覆国家政权，推翻社会主义制度的；</p>
                                                <p>
                                                    3、煽动分裂国家、破坏国家统一的；</p>
                                                <p>
                                                    4、煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                                                <p>
                                                    5、捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</p>
                                                <p>
                                                    6、宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</p>
                                                <p>
                                                    7、公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</p>
                                                <p>
                                                    8、损害国家机关信誉的；</p>
                                                <p>
                                                    9、其他违反宪法和法律行政法规的；</p>
                                                <p>
                                                    10、进行商业广告行为的</p>
                                                。
                                            </div>
                                            <p>
                                                用户不能利用邮件服务作连锁邮件，垃圾邮件或分发给任何未经允许接收信件的人。用户须承诺不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的和淫秽的信息资料。另外，用户也不能传输任何教唆他人构成犯罪行为的资料；不能传输长国内不利条件和涉及国家安全的资料；不能传输任何不符合当地法规、国家法律和国际法律的资料。未经许可而非法进入其它电脑系统是禁止的。若用户的行为不符合以上的条款，中国物品溯源平台网将取消用户服务帐号。</p>
                                        </li>
                                        <li>发送报告
                                            <p>
                                                本协议项下所有的通知均可通过重要页面公告、电子邮件或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</p>
                                        </li>
                                        <li>法律管辖
                                            <p>
                                                本协议的订立、执行和解释及争议的解决均应适用中国法律。</p>
                                            <p>
                                                如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向溯源平台所在地的人民法院提起诉讼。</p>
                                        </li>
                                        <li>其他规定
                                            <p>
                                                本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。</p>
                                            <p>
                                                如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
                                            <p>
                                                本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
                                        </li>
                                        <li>免责申明
                                            <p>
                                                用户明确同意其使用溯源平台网络服务所存在的风险将完全由其自己承担；因其使用溯源平台网络服务而产生的一切后果也由其自己承担，溯源平台对用户不承担任何责任。</p>
                                            <p>
                                                溯源平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
                                        </li>
                                        <li>解释权
                                            <p>
                                                本注册协议的解释权归溯源平台所有。如果其中有任何条款与国家的有关法律相抵触，则以国家法律的明文规定为准。</p>
                                        </li>
                                    </ol>
                                </div>
                               
                            </div>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
import {
  checkPhone,
  isPhone,
  checkUsername,
  checkPassword,
  checkName,
  checkEmailNumber,
} from "@/common/utils/index";
import { getRegisterCode, registerUser ,NOPREFIX} from "@/api/lib/api";
export default {
  data() {
    return {
      dialogVisible:false,
      logining: false,
      isGetphone: false,
      getPhoneIntval: null,
      phoneCode: "获取验证码",
      randomStr: null,
      imageUrl: null,
      imgCode: "/images/code.jpg",
      ruleForm: {
        avatar: "default",
        deptId: null,
        //description: "",
        //email: "",
        //gender: 0,
        phone: "",
        realname: "",
        role: [],
        status: 0,
        username: "",
      },

      rules: {
        realname: [
          {
            required: true,
            message: "请输入用户姓名",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          { validator: checkPhone, trigger: "blur" },
        ],
        phoneCode: [
          { required: true, trigger: "blur", message: "请输入手机验证码" },
        ],
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
          { validator: checkUsername, trigger: "blur" },
        ],
        //gender: [{ required: true, message: "请选择性别", trigger: "blur" }],

        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码为6-18位数字或字母",
            trigger: "blur",
          },
          { validator: checkPassword, trigger: "blur" },
        ],
        deptId: [
          {
            required: true,
            message: "请选择所属部门",
            trigger: "blur",
          },
        ],
        // email: [
        //   {
        //     required: true,
        //     message: "请输入邮箱地址",
        //     trigger: "blur",
        //   },
        //   { validator: checkEmailNumber, trigger: "blur" },
        // ],
        userGroupId: [
          { required: true, message: "请选择用户组", trigger: "blur" },
        ],
        role: [
          {
            required: true,
            message: "请选择所属角色",
            trigger: "blur",
          },
        ],
      },
      popDoms: null,
    };
  },
  methods: {
    getPhoneCode() {
      if (!isPhone(this.ruleForm.phone)) {
        this.$refs["ruleForm"].validateField("phone");
        return;
      }
      this.isGetphone = true;
      let n = 60;
      this.getPhoneIntval = setInterval(() => {
        if (n > 0) {
          n--;
          this.phoneCode = "重新获取(" + n + "s)";
        } else {
          this.getPhoneIntval = null;
          clearInterval(this.getPhoneIntval);
          this.phoneCode = "获取验证码";
          this.isGetphone = false;
        }
      }, 1000);
    },
    getImgCode() {
      this.randomStr = new Date().valueOf();
      this.imgCode = `${NOPREFIX}/valle/upms/api/register/code?randomStr=${this.randomStr}`;
    },
    handleReister() {
      let self = this;
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          const param = {
            username: this.ruleForm.username,
            phone: this.ruleForm.phone,
            password: this.ruleForm.password,
            code: this.ruleForm.phoneCode,
            //email: this.ruleForm.email,
            avatar: "default",
            //description: this.ruleForm.description,
           // gender: this.ruleForm.gender,
            randomStr: this.randomStr,
            realname: this.ruleForm.realname,
          };
          // const { msg } = await register(param)
          // this.$baseMessage(msg, 'success')
          registerUser(param).then((res) => {
            if (res.code === 0) {
              this.$message.success('注册成功');
              let timer = setTimeout(function(){
                self.$router.push('/login');
                clearTimeout(timer);
              },500)
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    goLogin(){
      this.$router.push('/login');
    },
    handleAvatarSuccess(res, file) {
      //this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    
  },
  mounted() {
   // this.open;
    this.randomStr = new Date().valueOf();
    this.imgCode = `${NOPREFIX}/valle/upms/api/register/code?randomStr=${this.randomStr}`;
  },
  beforeDestroy() {
    this.getPhoneIntval = null;
    clearInterval(this.getPhoneIntval);
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.detail-content{
  font-size: 15px;
  line-height: 1.5;
}
.register-container {
  @include themify() {
    width: 100%;
    height: 100%;
    // background-color: #3e3e58;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .content {
      .img-bg {
        position: fixed;
        width: 100%;
        height: 101%;
      }
      .title-wrapper {
        position: absolute;
        top: 4%;
        left: 20%;
        img {
          width: 246px;
        }
      }
      .yun {
        position: absolute;
      }
      .yun1 {
        top: 20%;
        right: 13%;
        animation: yun1 3s infinite alternate;
      }
      .yun2 {
        top: 10%;
        right: 23%;
        animation: yun2 4.5s infinite alternate;
      }
      .yun3 {
        top: 18%;
        right: 30%;
        animation: yun3 5s infinite alternate;
      }
      .register {
        width: 57vw;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0 0 20px rgb(102, 116, 169);
        position: absolute;
        top: 46%;
        transform: translateY(-50%);
        left: 20%;
        .register-content {
          width: 60%;
          margin: 0 auto;
          .link{
            color: #409eff;
          }
        }
        .register_title {
          font-size: 2.9vh;
          color: #333333;
          padding-top: 3vh;
          font-weight: 700;
          position: relative;
          text-align: center;
          .border-bottom {
            height: 8px;
          }

          .border-bottom:after {
            content: "";
            position: absolute;
            left: 9.5vw;
            top: auto;
            bottom: 0;
            right: auto;
            height: 3px;
            width: 4vw;
            background-color: #6674a9;
          }
        }
        .register-page {
          width: 100%;
          text-align: center;
          background: #ffffff00;
          padding: 2vh 2.3vw;
          margin: 0 auto;
          position: relative;
          z-index: 99;
          .phone-code {
            position: absolute;
            top: 14px;
            right: 25px;
            user-select: none;
          }
          .code-box {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 90px;
              height: 32px;
            }
          }
        }
      }
    }
    label.el-checkbox.rememberme {
      margin: 0px 0px 15px;
      text-align: left;
    }
  }
}

.bottom {
  height: 45vh;
  width: 100vw;
  background: #ffffff;
  z-index: 999;
  position: absolute;
  bottom: 0;
  .content {
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.pops {
  position: absolute;
  .content {
    height: 100%;
    width: 100%;
    position: relative;
    div[class*="pop"] {
      border-radius: 50%;
      background: rgba($color: #ffffff, $alpha: 0.1);
      position: absolute;
    }
    .pop1 {
      height: 60%;
      width: 60%;
      top: 0;
      left: 0;
    }
    .pop2 {
      height: 40%;
      width: 40%;
      right: 10%;
      top: 30%;
    }
    .pop3 {
      height: 40%;
      width: 40%;
      bottom: 10%;
      left: 20%;
    }
  }
}

.logo {
  text-align: center;
  margin-top: 6vh;
}

@keyframes yun1 {
  from {
    right: 13%;
  }
  to {
    right: 16%;
  }
}

@keyframes yun2 {
  from {
    right: 23%;
  }
  to {
    right: 26%;
  }
}

@keyframes yun3 {
  from {
    right: 30%;
  }
  to {
    right: 34%;
  }
}
@keyframes line {
  from {
    top: -200vh;
  }
  to {
    top: -100vh;
  }
}
</style>
<style lang="scss" >
.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 10vh;
    height: 10vh;
    line-height: 10vh;
    text-align: center;
  }
}
.span-blue{
  color:#409eff;
  display: inline-block;
  .span-login{
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
